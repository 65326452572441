import { createGlobalStyle, css } from 'styled-components';

import { COLORS, TYPOGRAPHY, ANIMATION } from '../../constants';

const buttonColor = COLORS.darkFontColor;
const buttonBackground = COLORS.iron;

const buttonDefault = css`
  appearance: none;
  background-color: ${buttonBackground};
  border: 0;
  border-radius: 2px;
  color: ${buttonColor};
  cursor: pointer;
  display: inline-block;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-family: ${TYPOGRAPHY.fontFamily.base};
  font-size: ${TYPOGRAPHY.fontSize.base};
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  padding: ${TYPOGRAPHY.spacing.half} ${TYPOGRAPHY.spacing.base};
  text-align: center;
  text-decoration: none;
  transition: background-color ${ANIMATION.duration} ${ANIMATION.timing};
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  box-shadow: 0 1px 1px 0 ${COLORS.black};
`;

// Add these global styles to buttons that dont have class 'btn' (which is what the design system uses)
export const Buttons = createGlobalStyle`
  :where(button, [type='button'], [type='reset'], [type='submit'], .button):not(.btn) {
    ${buttonDefault}

    &:hover,
    &:focus {
      background-color: #bebebe;
      color: ${buttonColor};
    }

    &:disabled {
      cursor: not-allowed;

      background-color: ${COLORS.romanSilver};
  
      &:hover {
        background-color: ${COLORS.romanSilver};
      }
    }

    &.red {
      background-color: ${COLORS.red};
      color: ${COLORS.hiliteFontColor};

      &:hover {
        background-color: #ed3c39;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: ${COLORS.red};

        &:hover {
          background-color: ${COLORS.red};
        }
      }

      &.round:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: ${COLORS.red};

        &:hover {
          background-color: ${COLORS.red};
        }
      }
    }

    &.blue {
      background-color: ${COLORS.curiousBlue};
      color: ${COLORS.hiliteFontColor};

      &:hover {
        background-color: #1777c2;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #1e88e5;

        &:hover {
          background-color: #1e88e5;
        }
      }
    }

    &.green {
      background-color: ${COLORS.green};
      color: ${COLORS.hiliteFontColor};

      &:hover {
        background-color: #419a45;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #419a45;

        &:hover {
          background-color: #419a45;
        }
      }
    }

    &.cta {
      font-size: ${TYPOGRAPHY.fontSize.subheadLarge};
    }

    &.round {
      border-radius: 50%;
      padding: 10px;
    }

    &.link-style {
      background: none;
      box-shadow: none;
      text-decoration: underline;
      padding: 0;
      transition: color ${ANIMATION.duration} ${ANIMATION.timing}, text-decoration-color ${ANIMATION.duration} ${ANIMATION.timing};
      color: ${COLORS.midFontColor};
      text-decoration-color: currentColor;

      &:hover {
        text-decoration-color: transparent;
        color: ${COLORS.defaultFontColor};
      }
    }

    .fa-fw {
      width: 1em;
      text-align: center;
    }

    i {
      display: inline-block;
      margin-right: 2px;
    }
  }
`;
